import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import { resetPassword } from '../../../store/actions/auth';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import ToasterBottom from '../../../components/UI/Toaster/ToasterBottom';
import bountyMediaBannerLogo from '../../../assets/images/bounty-login-banner.svg';
import inputChangedHandler from '../../../shared/inputChangeHandler';
import style from './ForgotPasswordPage.module.scss';
import UserService from '../../../api/Users';

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleReturn = (e) => {
    e.preventDefault();
    dispatch(resetPassword(false));
    navigate(-1);
  };
  return (
    <div className={style.container}>
      <section className={style.imgBox}>
        <img
          className={style.logo}
          src={bountyMediaBannerLogo}
          alt="Bounty Media logo"
        />
      </section>
      <section className={style.forgotPasswordBox}>
        <button
          onClick={handleReturn}
          className={`${style.backIcon} ${style.return}`}
        >
          <FontAwesomeIcon size="xl" width="54" icon={faArrowLeftLong} />
          <p>Return</p>
        </button>
        <Content />
      </section>
    </div>
  );
};
const Content = () => {
  const [form, setForm] = useState({
    formIsValid: false,
    controls: {
      email: {
        label: 'email',
        value: '',
        valid: true,
        error: '',
        validation: { required: true, isEmail: true },
      },
    },
  });
  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const closeToast = () => {
    setToast((state) => ({ ...state, show: false }));
  };
  const displayErrorMessage = () =>
    !form.formIsValid ? (
      <p className="authFormErrorText text-capitalize">
        {form.controls.email.error}
      </p>
    ) : null;
  return (
    <div className={style.contentBox}>
      <div className="mb-2 text-center">
        <h3 style={{ color: '#ff6f00' }}>Forgot your password?</h3>
      </div>
      <div className="mb-4 text-center">
        If you have forgotten your password, enter your accounts email below and
        click the reset button. You will receive an email that contains a link
        to set a new password.
      </div>
      <div>
        <Container fluid>
          <form
            className="authForm"
            onSubmit={async (e) => {
              e.preventDefault();

              setForm((prevState) => {
                const [updatedControls, newFormIsValid] = inputChangedHandler(
                  prevState.controls.email.value,
                  prevState,
                  'email',
                );
                return {
                  controls: updatedControls,
                  formIsValid: newFormIsValid,
                };
              });

              if (form.controls.email.value.length > 0 && form.formisValid) {
                try {
                  await UserService.sendResetPasswordViaEmail(
                    form.controls.email.value,
                  );

                  setToast({
                    show: true,
                    success: true,
                    body: 'Reset Password mail is sent. Please check your email',
                  });
                } catch (error) {
                  setToast({
                    show: true,
                    success: false,
                    body: error.response.data.message,
                  });
                }
              }
            }}
          >
            <Input
              additionalClasses="formInput"
              elementType="input"
              elementConfig={{
                placeholder: 'Enter Email Address',
                type: 'email',
              }}
              error
              invalid={!form.controls.email.valid}
              touched
              shouldValidate
              required={form.controls.email.validation.required}
              changed={(e) => {
                setForm((prevState) => {
                  const [updatedControls, newFormIsValid] = inputChangedHandler(
                    e.target.value,
                    prevState,
                    'email',
                  );
                  return {
                    controls: updatedControls,
                    formisValid: newFormIsValid,
                  };
                });
              }}
            />
            {displayErrorMessage()}
            <Button
              additionalClasses="mt-2 text-capitalize"
              dataTestAttribute="login-button"
            >
              Reset Password
            </Button>
          </form>
        </Container>
        <ToasterBottom toast={toast} closeToast={closeToast} />
      </div>
    </div>
  );
};
export default ForgotPasswordPage;
