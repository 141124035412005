import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { CampaignAccordionItem } from '../CampaignAccordion/CampaignAccordion';

import PaywallApi from '../../../api/Paywall';
import FormikInput from '../../UI/FormikInput/FormikInput';
import Button from '../../UI/Button/Button';
import Modal from '../../UI/Modal/Modal';

import styles from '../CampaignDetail.module.scss';

import {
  getQualificationQuestionQuestionField,
  getQualificationQuestionAnswersField,
} from '../controls/controls.qualifications';

const CampaignSectionQualifications = ({
  step,
  // displayFields, **Not needed as fields are dynamic**
  fieldKeys,
  formik,
  showNextSection,
  validationSchema,
  isLastStep,
  onSaveAction,
  sectionsRef,
  countData,
}) => {
  const [questions, setQuestions] = useState(
    formik.values.paywall?.qualificationRequirements?.questions ?? [],
  );
  const [qualifyingQuestionOptions, setQualifyingQuestionOptions] = useState(
    [],
  );
  const [questionToRemove, setQuestionToRemove] = useState(null);

  useEffect(() => {
    setQuestions(
      formik.values.paywall?.qualificationRequirements?.questions ?? [],
    );
  }, [formik.values.paywall?.qualificationRequirements?.questions]);

  useEffect(() => {
    PaywallApi.getQuestions()
      .then((response) => {
        const formattedQuestions = response.data.questions.map((question) => {
          return {
            label: question.questionLabel.en,
            value: question.questionId,
            answers: question.answers.map((answer) => {
              return {
                label: answer.answerLabel.en,
                value: answer.answerId,
              };
            }),
          };
        });
        setQualifyingQuestionOptions(formattedQuestions);
      })
      .catch(() => {
        console.error('error');
      });
  }, []);

  const filterQuestions = (idx) => {
    const selectedQuestions = questions
      ?.map((q) => q.questionId)
      .filter((q, i) => i !== idx);
    return qualifyingQuestionOptions.filter((q) => {
      return !selectedQuestions.includes(q.value);
    });
  };

  const getAnswers = (idx) => {
    const parentQuestion = questions[idx];
    if (!parentQuestion.questionId) return [];
    return qualifyingQuestionOptions.filter((q) => {
      return parentQuestion.questionId === q.value;
    })[0]?.answers;
  };

  const addQuestion = () => {
    const newQuestions = [
      ...questions,
      {
        questionId: '',
        questionLabel: {
          en: '',
        },
        answers: [],
        isNewQuestion: true,
      },
    ];
    formik.setFieldValue(
      'paywall.qualificationRequirements.questions',
      newQuestions,
    );
  };

  const removeQuestion = useCallback(
    (idx) => {
      const newQuestions = questions.filter((q, i) => i !== idx);
      formik.setFieldValue(
        'paywall.qualificationRequirements.questions',
        newQuestions,
      );
      setQuestionToRemove(null);
    },
    [formik, questions],
  );
  useEffect(() => {
    if (Number(countData.connections) > 0) {
      questions.map((question, idx) => {
        if (question.isNewQuestion) {
          removeQuestion(idx);
        }
      });
    }
  }, [countData.connections, questions, removeQuestion]);

  const allowAddRemove = useMemo(() => {
    return Number(countData.connections) === 0;
  }, [countData.connections]);

  return (
    <>
      <CampaignAccordionItem
        step={step}
        sectionKey="qualifications"
        title="Qualifying Questions"
        formik={formik}
        fieldKeys={fieldKeys}
        showNextSection={showNextSection}
        validationSchema={validationSchema}
        isLastStep={isLastStep}
        onSaveAction={onSaveAction}
        sectionsRef={sectionsRef}
      >
        <CampaignAccordionItem.Tooltip>
          <p>
            The specified target group will be asked a set of questions as part
            of the campaign process.
          </p>
        </CampaignAccordionItem.Tooltip>
        <div>
          {questions?.map((question, idx) => {
            return (
              <Row key={`qualificationRequirements-${idx}`}>
                <Col xs={6} key={idx}>
                  <FormikInput
                    field={getQualificationQuestionQuestionField(idx)}
                    options={filterQuestions(idx)}
                    formik={formik}
                    validationSchema={validationSchema}
                    showError={false}
                  />
                </Col>
                <Col xs={6}>
                  <Row>
                    <Col xs="11">
                      <FormikInput
                        field={getQualificationQuestionAnswersField(idx)}
                        options={getAnswers(idx)}
                        formik={formik}
                        validationSchema={validationSchema}
                      />
                    </Col>
                    {allowAddRemove && (
                      <Col
                        xs={1}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() => {
                            if (questions[idx].questionId) {
                              setQuestionToRemove(idx);
                              return;
                            }
                            removeQuestion(idx);
                          }}
                          title="Remove module"
                          className={styles.iconStyling}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            );
          })}
          {questions.length < qualifyingQuestionOptions.length &&
            allowAddRemove && (
              <div className="mt-3">
                <Button
                  additionalClasses="btnDefault btnDefault--medium"
                  clicked={addQuestion}
                >
                  Add Question
                </Button>
              </div>
            )}

          {!allowAddRemove && (
            <div>
              <p className={styles.infoText}>
                *Additional qualifying questions cannot be added once the
                campaign has started and has received connections.
              </p>
            </div>
          )}
        </div>
      </CampaignAccordionItem>
      <Modal
        title="Delete Qualifying Question"
        cancel="No"
        save="Yes"
        handleSave={() => removeQuestion(questionToRemove)}
        show={questionToRemove !== null}
        handleClose={() => setQuestionToRemove(null)}
      >
        <p>
          You are about to delete{' '}
          <strong>Question {questionToRemove + 1}</strong> from the campaign.
          This action is not reversible. Are you sure you want to proceed?
        </p>
      </Modal>
    </>
  );
};

export default CampaignSectionQualifications;
